<template>
  <form data-vv-scope="form-origin-destination-bulk">
    <div class="row" v-show="!opened">
      <div class="col-12">
        <a v-on:click="toggleVisibility()" class="btn btn-outline-light">Adicionar origens e destinos em grupo</a>
      </div>
    </div>
    <div class="row" v-show="opened">
      <!-- Origens -->
      <div class="col-md-6 col-12">
        <label for="origin-bulk">Origens</label>
        <multiselect
          id="origin-bulk"
          v-model="value.origin"
          :multiple="true"
          :max="50"
          track-by="id"
          label="nome"
          :allow-empty="true"
          placeholder="Selecione uma ou mais opções"
          :close-on-select="false"
          :clear-on-select="true"
          @search-change="asyncFindOriginsDestinations"
          :custom-label="originDestinationCustomLabel"
          :internal-search="false"
          :options="originsDestinationsList"
          :show-labels="false">
          <template slot="singleLabel" slot-scope="{ option }">
            <strong>{{ option.nome }}, {{ option.tipo === 1 ? 'Porto' : 'Aeroporto' }}</strong>
          </template>
          <template slot="option" slot-scope="{ option }">
            <strong>{{ option.nome }}, {{ option.tipo === 1 ? 'Porto' : 'Aeroporto' }}</strong>
          </template>
        </multiselect>
      </div>
      <!-- Destinos -->
      <div class="col-md-6 col-12">
        <label for="destination-bulk">Destinos</label>
        <multiselect
          id="destination-bulk"
          v-model="value.destination"
          :multiple="true"
          :max="50"
          track-by="id"
          label="nome"
          :allow-empty="true"
          placeholder="Selecione uma ou mais opções"
          :close-on-select="false"
          :clear-on-select="true"
          @search-change="asyncFindOriginsDestinations"
          :custom-label="originDestinationCustomLabel"
          :internal-search="false"
          :options="originsDestinationsList"
          :show-labels="false">
          <template slot="singleLabel" slot-scope="{ option }">
            <strong>{{ option.nome }}, {{ option.tipo === 1 ? 'Porto' : 'Aeroporto' }}</strong>
          </template>
          <template slot="option" slot-scope="{ option }">
            <strong>{{ option.nome }}, {{ option.tipo === 1 ? 'Porto' : 'Aeroporto' }}</strong>
          </template>
        </multiselect>
      </div>
      <!-- Tier -->
      <div class="mt-3 col-md-2 col-12">
        <label for="tier-bulk">Tier</label>
        <v-select id="tier-bulk" label="code" class="md-form-control" :clearable="false" :searchable="false" v-model="value.tier"  :options="tierList" >
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.code }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.code }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="!validTier" class="royalc-error-input">Campo obrigatório</small>
        </transition>
      </div>
      <!-- Approach -->
      <div class="mt-3 col-md-6 col-12">
      <label for="approach-bulk">Estratégia</label>
      <v-select id="approach-bulk" label="name"  class="md-form-control" v-model="value.approach" :clearable="false" :searchable="false"  :options="strategyList" @input="onSelectApproach" >
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.name }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.name }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="">
        <small v-if="!validApproach" class="royalc-error-input">Campo obrigatório</small>
      </transition>
    </div>
      <!--  Opções por origem e destino  -->
      <div class="mt-3 col-md-2 col-12">
        <label>Opções</label>
        <input class="md-form-control" v-show="!value.approach || value.approach.id === 1"  v-model="value.options_per_origin_destination" type="number" >
        <input class="md-form-control option-disabled" title="Opção indisponível para a estratégia selecionada" v-show="value.approach && value.approach.id !== 1"  disabled type="number" >
        <transition name="slide" mode="">
          <small v-if="!validOptionsPerOriginDestination" class="royalc-error-input">Campo obrigatório</small>
          <small v-else-if="!validOptionsPerOriginDestinationRange" class="royalc-error-input">Valor deve ser maior que 0</small>
        </transition>
      </div>
      <!-- Minimum_validity -->
      <div class="mt-3 col-md-2 col-12">
        <label for="minimum_validity-bulk">Validade (Dias)</label>
        <input id="minimum_validity-bulk" class="md-form-control" v-model="value.minimum_validity"   type="number" >
        <transition name="slide" mode="">
          <small v-if="!validMinimumValidity" class="royalc-error-input">Campo obrigatório</small>
          <small v-else-if="!validMinimumValidityRange" class="royalc-error-input">Valor deve ser maior que 0</small>
        </transition>
      </div>
      <!-- long_term -->
      <div class="mt-3 mt-3 col-md-4 col-12" v-if="showLongTerm">
        <label class="ui-switch no-margin small switch-solid switch-warning">
          <input id="long_term-bulk" tabindex="-1" :value="1" type="checkbox" ref="long_term" :checked="(value.long_term == 1)" v-on:change="toggleLongTerm"><span></span>
        </label>
        <label class="ml-2" for="long_term-bulk">Long Term</label><i :title="'Deseja enviar opções com o Tipo de Frete Long Term?'" class="fa fa-question-circle"></i>
      </div>
      <!-- aceita_transbordo -->
      <div class="mt-3 col-md-4 col-12">
        <label class="ui-switch no-margin small switch-solid switch-warning">
          <input id="aceita_transbordo-bulk" tabindex="-1" :value="1" type="checkbox" ref="long_term" :checked="(value.aceita_transbordo == 1)" v-on:change="toggleAceitaTransbordo"><span></span>
        </label>
        <label class="ml-2" for="aceita_transbordo-bulk">Aceita Transbordo</label><i :title="'Deseja enviar opções com o serviço marítimo com transbordo?'" class="fa fa-question-circle"></i><br/>
      </div>
      <!-- Receber -->
      <div class="mt-3 col-md-4 col-12">
        <label class="ui-switch no-margin small switch-solid switch-warning">
          <input id="status-bulk" tabindex="-1" :value="1" type="checkbox" ref="status" :checked="(value.status == 1)" v-on:change="toggleStatus"><span></span>
        </label>
        <label class="ml-2" for="status-bulk">Enviar Opção</label><i :title="'Deixe esse campo inativo, quando cliente trabalha, mas não deseja receber tarifário para essa origem x destino'" class="fa fa-question-circle"></i>
      </div>
      <!-- Ações -->
      <div class="col-12 mt-5">
        <a v-on:click="addOriginDestinationGroup()" class="mt-3 mb-5 btn btn-outline-light pull-right">Adicionar ao tarifário</a>
        <a v-on:click="toggleVisibility()" class="mt-3 mb-5 btn btn-link pull-right">Cancelar</a>
      </div>
    </div>
  </form>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Services
import MailService from '@/services/MailService'

export default {
  name: 'OriginDestinationBulkForm',
  props: {
    opened: {
      type: Boolean,
      required: false,
      default: false
    },
    showLongTerm: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    tierList: {
      type: Array,
      required: true
    },
    strategyList: {
      type: Array,
      required: true
    },
    originDestinationFetchOptions: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    removeOriginDestination: {
      type: Function,
      required: false
    },
    addOriginDestination: {
      type: Function,
      required: false
    },

    enableStatus: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    apiUrl: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    idField: {
      type: String,
      default: 'id'
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    valueTransform: {
      type: Function,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    },
    delay: {
      required: false,
      type: Number,
      default: 500
    }
  },
  components: {
    vSelect,
    Multiselect
  },
  created () {
    let _this = this
    _this.id = 'origin_destination_form_' + _this._uid
  },
  data () {
    return {
      submited: false,
      searchTerm: null,
      selectedValue: null,
      originsDestinationsList: []
    }
  },
  methods: {
    addOriginDestinationGroup () {
      this.$emit('addOriginsDestinationsGroup', this.value)
    },
    toggleVisibility () {
      this.$emit('toggleVisibility', !this.opened)
    },
    asyncFindOriginsDestinations (query) {
      let _this = this
      window.clearTimeout(_this.timer)

      this.timer = window.setTimeout(function () {
        if (query) {
          _this.isLoadingSearch = true

          let filter = {
            searchTerm: query,
            field: 'Origem'
          }

          MailService.getVariableSearch(filter).then(res => {
            _this.originsDestinationsList = []
            _this.originsDestinationsList = res.data.data
            _this.isLoadingSearch = false
          })
        } else {
          _this.originsDestinationsList = []
        }
      }, _this.delay)
    },
    originDestinationCustomLabel ({ nome, tipo }) {
      // eslint-disable-next-line camelcase
      return `${nome}, ${tipo === 1 ? 'Porto' : 'Aeroporto'}`
    },
    onSelectApproach (val) {
      if (val) {
        if (val.id === 1) {
          this.value.options_per_origin_destination = 1
        } else {
          this.value.options_per_origin_destination = 3
        }
      }
    },
    toggleStatus (e) {
      this.value.status = ((e.target.checked) ? 1 : 2)
      this.$emit('input', { ...this.value })
    },
    toggleLongTerm (e) {
      this.value.long_term = ((e.target.checked) ? 1 : 2)
      this.$emit('input', { ...this.value })
    },
    toggleAceitaTransbordo (e) {
      this.value.aceita_transbordo = ((e.target.checked) ? 1 : 2)
      this.$emit('input', { ...this.value })
    },
    onSelectOrigin (val) {
      this.value.tier_local = null
    },
    onRowClicked (dataItem, dataIndex, event) {
      this.$emit('rowClicked', { data: dataItem, index: dataIndex, event: event })
      return true
    },
    isIncluded: function (val) {
      return false
    },
    toggleSelectAll () {
      let activeStatus = this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      if (this.allSelected) {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = 2
          this.value.splice(i, 1, item)
        }
      } else {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = activeStatus
          this.value.splice(i, 1, item)
        }
      }
      this.$emit('toggleAll', this.value)
    },
    addSelectedOption (val) {
      let defaultStatus = 1
      if (this.enableStatus !== null) {
        defaultStatus = this.enableStatus
      }

      if (val != null) {
        if (this.valueTransform) {
          val = this.valueTransform(val)
        }

        if (!this.isIncluded(val)) {
          val.status = defaultStatus
          this.value.push(val)
          this.$emit('onInput', { data: val })
        }
        this.$refs.filterField.clearSelection()
      }
    },
    removeSelectedOption (val) {
      if (val != null) {
        let _this = this
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element[_this.idField] === val
        })
        // remove element from selected options
        this.value.splice(index, 1)
        this.$emit('onRemoved', val)
      }
    },
    validate (value) {
      let _this = this
      return new Promise(function (resolve, reject) {
        let valid = true

        // Origem
        if (!_this.validOrigin) {
          valid = false
        }

        // Destino
        if (!_this.validDestination) {
          valid = false
        }

        // Tier
        if (!_this.validTier) {
          valid = false
        }

        // Estratégia
        if (!_this.validApproach) {
          valid = false
        }

        // Opções
        if (_this.validApproach && _this.value.approach.id === 1 && !_this.validOptionsPerOriginDestination) {
          valid = false
        }

        if (_this.validApproach && _this.value.approach.id === 1 && !_this.validOptionsPerOriginDestinationRange) {
          valid = false
        }

        // Validade (dias)
        if (!_this.validMinimumValidity) {
          valid = false
        }

        if (!_this.validMinimumValidityRange) {
          valid = false
        }

        resolve(valid)
      })
    },
    filter (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1) || (option.sigla && this.$util.slugify(option.sigla).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    }
  },
  computed: {
    allSelected () {
      let _this = this
      if (_this.value.length <= 0) {
        return false
      }

      let activeStatus = _this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      let activeValues = _this.value.filter(function (x) {
        return x.status === activeStatus
      })

      if (activeValues.length === _this.value.length) {
        return true
      }

      return false
    },
    computedValue: function () {
      if (this.enableStatus === null) {
        return this.value
      }

      let _self = this

      return this.value.filter(function (status) {
        return status.status === _self.enableStatus
      })
    },
    validOrigin () {
      return this.value.origin
    },
    validDestination () {
      return this.value.destination
    },
    validTier () {
      return this.value.tier
    },
    validApproach () {
      return this.value.approach
    },
    validMinimumValidity () {
      return this.value.minimum_validity
    },
    validMinimumValidityRange () {
      let valid = true
      if (!Number.isInteger(parseInt(this.value.minimum_validity))) {
        valid = false
      }

      if (parseInt(this.value.minimum_validity) <= 0) {
        valid = false
      }

      return valid
    },
    validOptionsPerOriginDestination () {
      return this.value.options_per_origin_destination
    },
    validOptionsPerOriginDestinationRange () {
      let valid = true
      if (!Number.isInteger(parseInt(this.value.options_per_origin_destination))) {
        valid = false
      }

      if (parseInt(this.value.options_per_origin_destination) <= 0) {
        valid = false
      }

      return valid
    }
  }
}
</script>

<style scoped>
.buttonClose:not(:disabled):not(.disabled) {
  cursor: pointer;
  font-size: 12px;
}

.option-disabled {
  cursor: not-allowed;
  color: #83dadb;
}
</style>
