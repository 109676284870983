<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="form-tariff">
    <div class="row justify-content-md-center">
      <!-- Cliente -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <advanced-search emptyMessage="Nenhum cliente selecionado" name="task.customer" rootClassName="col-sm-10 row" @onSelect="setCustomer" :value="task.customer" :clearable="false" :options="customerList" v-bind:fetchOptions="customerFetchOptions">
                <template v-slot:info>
                  <div class="row" v-if="task.customer">
                    <div class="col-md-12">
                      <strong>Tier Local</strong>
                    </div>
                    <div class="col-md-6">
                      <strong>Expo:</strong> {{ (tierLocal.tier_local_expo) ? tierLocal.tier_local_expo.codigo : 'Não Exibir' }}
                    </div>
                    <div class="col-md-6">
                       <strong>Impo:</strong>{{ (tierLocal.tier_local_impo) ? tierLocal.tier_local_impo.codigo : 'Não Exibir' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <transition name="slide" mode="">
                        <small v-if="!validCustomer()" class="royalc-error-field">{{ 'Campo obrigatório.' }}</small>
                      </transition>
                    </div>
                  </div>
                </template>
              </advanced-search>

              <custom-switch
                :listItems="[{text: 'Disparos', value: 1}]"
                v-model="task.status"
                name="task.status"
                type="checkbox"
                stateClassName="p-primary"
                :error="errors.first('task.status')">
              </custom-switch>
            </div>
          </div>
        </div>
      </div>
      <!-- Recipientes -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.contacts') }}</h5>
            <advanced-multiple-select :custom-label="customLabel" :searchable="true" :whatsappToggle="true" :disabled="this.task.customer === null" id-field="id"  labelField="nome" :value="task.contacts" :options="contactList" :tableFields="contactFields" v-bind:fetchOptions="customerContactFetchOptions"></advanced-multiple-select>
          </div>
        </div>
      </div>
      <!-- Origens e destinos -->
      <div class="col-12 col-sm-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.originsDestinations') }}</h5>
            <origin-destination-bulk-form v-on:toggleVisibility="toggleVisiblity"  v-on:addOriginsDestinationsGroup="addOriginDestinationGroup"   :opened="OriginDestinationGroup.visible" :show-long-term="isSuperAdmin" :strategy-list="strategyList" :tier-list="tierList" v-model="OriginDestinationGroup" name="origin-destination-group-form" />
            <div class="col-12 col-md-12" v-show="!OriginDestinationGroup.visible">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th width="23%">
                    Origem
                  </th>
                  <th width="23%">
                    Destino
                  </th>
                  <th width="10%">
                    Tier
                  </th>
                  <th width="30%">
                    Estratégia
                  </th>
                  <th>
                    Opções
                  </th>
                  <th width="10%">
                    Validade (Dias)
                  </th>
                  <th width="4%" v-if="isSuperAdmin">
                    <i :title="'Deseja enviar opções com o Tipo de Frete Long Term?'" class="fa fa-question-circle"></i>
                    Long Term
                    <label class="ui-switch no-margin small switch-solid switch-warning">
                      <input type="checkbox" @input="toggleLongTermTransbordoAll()" :checked="longTermAllSelected"><span></span>
                    </label>
                  </th>
                  <th width="4%">
                    <i :title="'Deseja enviar opções com o serviço marítimo com transbordo?'" class="fa fa-question-circle"></i><br/>
                    Aceita Transbordo
                    <label class="ui-switch no-margin small switch-solid switch-warning">
                      <input type="checkbox" @input="toggleAceitaTransbordoAll()" :checked="aceitaTransbordoAllSelected"><span></span>
                    </label>
                  </th>
                  <th width="4%">
                    <i :title="'Deixe esse campo inativo, quando cliente trabalha, mas não deseja receber tarifário para essa origem x destino'" class="fa fa-question-circle"></i>
                    Enviar opção
                    <label class="ui-switch no-margin small switch-solid switch-warning">
                      <input type="checkbox" @input="toggleReceberAll()" :checked="receberAllSelected"><span></span>
                    </label>
                  </th>
                  <th style="min-width:110px;">
                  </th>
                </tr>
                </thead>
                <tbody>
                  <origin-destination-form v-for="(originDestination,index) in task.originsDestinations"
                                           :name="'originDestination_' + index"
                                           :row-index="index" :value="originDestination"
                                           :ref="'originDestination_' + index"
                                           :add-origin-destination="addOriginDestination"
                                           :remove-origin-destination="removeOriginDestination"
                                           :show-long-term="isSuperAdmin"
                                           :strategy-list="strategyList"
                                           :origin-destination-fetch-options="originDestinationFetchOptions"
                                           :origin-destination-list="originsDestinationsList"
                                           :tierList="tierList"
                                           v-bind:key="index"
                  >
                  </origin-destination-form>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Material  -->
      <div class="col-lg-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.commoditie') }}</h5>
            <advanced-multiple-select  :value="task.commodities" :options="commoditiesList" :no-options="'Buscar Material'"  labelField="nome"  :tableFields="commoditiesFields" v-bind:fetchOptions="commoditiesFetchOptions"></advanced-multiple-select>
          </div>
        </div>
      </div>

      <!-- Armador  -->
      <div class="col-lg-8">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
            <h5 class="box-title mb-3 col-9">{{ $t('schedules.shipowners') }}</h5>
              <custom-switch
                :listItems="[{text: 'Exibir Armador', value: 1}]"
                v-model="task.showShipowner"
                name="task.showShipowner"
                type="checkbox"
                :value="task.showShipowner"
                stateClassName="p-primary"
                rootClassName="col-sm-3 mb-3 mt-3"
                :disabled="!isShowShipownerEnabled"
                :error="errors.first('task.showShipowner')">
              </custom-switch>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 class="mt-3">{{ $t('schedules.shipowners_accept') }}</h6>
                <advanced-multiple-select :enableStatus="1" :value="task.shipowners" :options="transportCompanyList" labelField="nome"   :emptyMessage="$t('all')" :no-options="'Buscar Armador'" :tableFields="transportCompanyFields" v-bind:fetchOptions="transportCompanyFetchOptions"></advanced-multiple-select>
              </div>
              <div class="col-md-6">
                <h6 class=" mt-3">{{ $t('schedules.shipowners_refuse') }}</h6>
                <advanced-multiple-select :enableStatus="2" :value="task.shipowners" :options="transportCompanyList" labelField="nome"  :emptyMessage="$t('none')" :no-options="'Buscar Armador'" :tableFields="transportCompanyFields" v-bind:fetchOptions="transportCompanyFetchOptions"></advanced-multiple-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center mb-5">
      <!-- Tipo de Equipamento -->
      <div class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.equipment') }}</h5>
            <advanced-multiple-select  :value="task.equipments"  :options="equipmentList" labelField="description"  :no-options="'Buscar Equipamento'"   :tableFields="equipmentFields" v-bind:fetchOptions="equipmentFetchOptions"></advanced-multiple-select>
          </div>
        </div>
      </div>

      <!-- Frequência -->
      <div class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.frequency') }}</h5>

              <div class="row">
                <div class="col-sm-6 form-group mb-6">
                  <custom-input
                    v-model="task.period"
                    label="Enviar a cada"
                    name="task.period"
                    type="number"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    v-validate="{ required: true }"
                    :error="errors.first('task.period')">
                  </custom-input>
                </div>
                <div class="col-sm-6 form-group mb-6">
                  <label>&nbsp;</label>
                  <v-select label="name"  :clearable="false" :searchable="false" v-model="task.recurrence"  :options="recurrenceList" @input="onSelectRecurrence"  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div v-if="showWeekdayConfig"  class="col-sm-12 form-group mb-12">
                  <label>Repetir</label>
                  <multiple-toggle :options="weekDayList"></multiple-toggle>
                  <transition name="slide" mode="">
                    <small v-if="!validWeekDay()" class="royalc-error-field">{{ 'Você deve selecionar pelo menos um dia da semana.' }}</small>
                  </transition>
                </div>
              </div>

          </div>
        </div>
      </div>

      <!-- Remetente -->
      <div class="col-12 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-2 mb-4">
                <label>{{$t('mails.template.sender')}}</label>
                <v-select label="email" name="task.sender" class="md-form-control"  :clearable="true" :searchable="true" v-model="task.sender"  :options="ccContactList" v-validate="{ required: true }">
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.email }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.email }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('task.sender')" class="royalc-error-field">{{ errors.first('task.sender') }}</small>
                </transition>
                <div class="font-11">
                  Não esqueça de preencher o CCO, se necessário receber a cópia dos e-mails.<br/>
                </div>
              </div>
              <!-- Default Sender-->
              <div class="col-md-12 mt-2 mb-4" v-show="task.sender && task.sender.id === 'vendedor'">
                <label>{{$t('mails.template.defaultSender')}}</label>
                <v-select label="email" name="task.defaultSender" class="md-form-control"  :clearable="true" :searchable="true" v-model="task.defaultSender"  :options="defaulSenderList" v-validate="{ required: (task.sender && task.sender.id === 'vendedor') }">
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.email }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.email }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('task.defaultSender')" class="royalc-error-field">{{ errors.first('task.defaultSender') }}</small>
                </transition>
                <div class="font-11">
                  Remetente utilizado quando Cliente:<br/>
                  - Não possuir nenhum vendedor vinculado;<br/>
                  - Vendedor vinculado for igual à "Alan" ou<br/>
                  - Vendedor vinculado estiver inativo.<br/>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Com cópia -->
      <div class="col-12 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-2 mb-4">
                <label>{{$t('mails.template.cc')}}</label>
                <multiselect
                  v-model="task.cc"
                  :multiple="true"
                  :max="50"
                  track-by="id"
                  label="name"
                  :allow-empty="true"
                  placeholder="Selecione uma ou mais opções"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :custom-label="mailCustomLabel"
                  :options="ccContactList"
                  :show-labels="false">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Com cópia oculta -->
      <div class="col-12 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-2 mb-4">
                <label>{{$t('mails.template.cco')}}</label>
                <multiselect
                  v-model="task.cco"
                  :multiple="true"
                  :max="50"
                  track-by="id"
                  label="name"
                  :allow-empty="true"
                  placeholder="Selecione uma ou mais opções"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :custom-label="mailCustomLabel"
                  :options="ccContactList"
                  :show-labels="false">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Restrições de envio -->
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">Restrições de envio</h5>
            <div>
              <custom-switch
                :listItems="[{text: 'Não enviar se cliente possuir um ou mais processos aguardando embarque', value: 1}]"
                v-model="task.notSendWithOpenedProcesses"
                name="task.notSendWithOpenedProcesses"
                type="checkbox"
                stateClassName="p-primary"
                rootClassName="text-left col-12 mt-2 mb-6"
                :error="errors.first('task.notSendWithOpenedProcesses')">
                <template slot="helper">
                  <i class="ft-help-circle"></i><md-tooltip md-direction="right">
                  Regra:<br/>
                   - Processos de agenciamento de carga<br/>
                   - Com data de embarque não informada<br/>
                   - Tipo diferente de VIP<br/>
                   - Status do processo Diferente de JUNK e Em cobrança Judicial<br/>
                   - Situação diferente de Finalizado e Cancelado<br/>
                </md-tooltip>
                </template>
              </custom-switch>
            </div>
            <div>
              <custom-switch
                :listItems="[{text: 'Não enviar se cliente possuir uma ou mais propostas em aberto', value: 1}]"
                v-model="task.notSendWithOpenedProposals"
                name="task.notSendWithOpenedProposals"
                type="checkbox"
                stateClassName="p-primary"
                rootClassName="text-left col-12 mt-2 mb-6"
                :error="errors.first('task.notSendWithOpenedProposals')">
                <template slot="helper">
                  <i class="ft-help-circle"></i><md-tooltip md-direction="right">
                  Regra:<br/>
                  - Propostas com situação diferente de  1: Aguardando aprovação, 4: Não enviada e 6: Enviada<br/>
                  - Com Data da proposta aberta a 10 dias ou menos<br/>
                </md-tooltip>
                </template>
              </custom-switch>
            </div>
          </div>
        </div>
      </div>

      <!-- Formato -->
      <div class="col-lg-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label for="attach-format">Formato do anexo</label>
                <v-select id="attach-format"
                  label="name"
                  :clearable="false"
                  :searchable="false"
                  v-model="task.attachFormat"
                  :options="attachFormatList"
                  @input="onSelectAttachFormat">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveTask">Salvar</button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import MultipleToggle from '@/components/Forms/MultipleToggle.vue'
import AdvancedSearch from '@/components/Forms/AdvancedSearch.vue'
import AdvancedMultipleSelect from '@/components/Forms/AdvancedMultipleSelect.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'
import CustomInput from '@/components/Forms/CustomInput.vue'
import OriginDestinationForm from './OriginDestinationForm'
import OriginDestinationBulkForm from './TaskBulk/OriginDestinationBulkForm'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import UserService from '@/services/UserService'
import OriginDestinationService from '@/services/OriginDestinationService'
import EquipmentService from '@/services/EquipmentService'
import TierService from '@/services/TierService'
import TaskService from '@/services/TaskService'
import CommoditieService from '@/services/CommoditieService'
import TransportCompanyService from '@/services/TransportCompanyService'
import MailService from '@/services/MailService'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s'
    }
  },
  data () {
    return {
      delay: 500,
      isLoading: true,
      OriginDestinationGroup: {
        tier: null,
        status: 1,
        long_term: false,
        aceita_transbordo: true,
        options_per_origin_destination: null,
        approach: null,
        minimum_validity: null,
        origin: null,
        destination: null,
        visible: false
      },
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task',
      // List of options to select on select input
      customerList: [],
      employeeList: [],
      ccContactList: [],
      originsDestinationsList: [],
      commoditiesList: [],
      transportCompanyList: [],
      tierLocal: {
        tier_local_expo: null,
        tier_local_impo: null
      },
      weekDayList: [
        {
          'id': 1,
          'name': 'D',
          'title': 'Domingo',
          'active': false
        },
        {
          'id': 2,
          'name': 'S',
          'title': 'Segunda-feira',
          'active': false
        },
        {
          'id': 3,
          'name': 'T',
          'title': 'Terça-feira',
          'active': false
        },
        {
          'id': 4,
          'name': 'Q',
          'title': 'Quarta-feira',
          'active': false
        },
        {
          'id': 5,
          'name': 'Q',
          'title': 'Quinta-feira',
          'active': false
        },
        {
          'id': 6,
          'name': 'S',
          'title': 'Sexta-feira',
          'active': false
        },
        {
          'id': 7,
          'name': 'S',
          'title': 'Sábado',
          'active': false
        }
      ],
      contactList: [],
      equipmentList: [],
      tierList: [],
      strategyList: [],
      currencyList: [],
      recurrenceList: [],
      //  Selected Values (Form)
      task: {
        notSendWithOpenedProcesses: null,
        notSendWithOpenedProposals: null,
        isTaskTariffBulk: false,
        cc: [],
        cco: [],
        sender: null,
        defaultSender: null,
        customer: null,
        tier: null,
        strategy: null,
        period: 1,
        contacts: [],
        commodities: [],
        shipowners: [],
        origins: [],
        originsDestinations: [{
          long_term: 2,
          aceita_transbordo: 1,
          status: 1,
          origin: null,
          destination: null,
          tier: null,
          approach: null,
          options_per_origin_destination: null,
          minimum_validity: null
        }],
        destinations: [],
        equipments: [],
        optionsPerOriginDestination: 1,
        minimumValidity: 10,
        recurrence: null,
        showShipowner: null,
        status: 1,
        attachFormat: {
          id: 1,
          name: 'PDF'
        }
      },
      // Configuration to mount table of selected values
      originsDestinationsFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      transportCompanyFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('transport_company.name'),
          sortField: 'nome',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      commoditiesFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      contactFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'nome',

          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nickname',
          title: this.$i18n.t('origin_destination.nickname'),
          sortField: 'nickname',

          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('origin_destination.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'phone',
          title: this.$i18n.t('origin_destination.phone'),
          sortField: 'phone',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      equipmentFields: [
        {
          name: 'description',
          title: this.$i18n.t('equipment.description'),
          sortField: 'description',

          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      attachFormatList: [
        {
          id: 1,
          name: 'PDF'
        },
        {
          id: 2,
          name: 'Planilha'
        }
      ]
    }
  },
  components: {
    CustomInput,
    OriginDestinationForm,
    Loading,
    MultipleToggle,
    AdvancedSearch,
    AdvancedMultipleSelect,
    vSelect,
    CustomSwitch,
    Multiselect,
    OriginDestinationBulkForm
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    // Load basilares data
    OriginDestinationService.getOriginsDestinations().then(res => {
      _this.hasData = res.data.hasData
      _this.originsDestinationsList = res.data.data
      UserService.getUsers({}).then(res => {
        _this.employeeList = res.data.data
        MailService.getContacts({}, 1, 1000).then(res => {
          _this.ccContactList = res.data.data
          EquipmentService.getEquipments().then(res => {
            _this.equipmentList = res.data.data
            TransportCompanyService.getTransportCompanies().then(res => {
              _this.transportCompanyList = res.data.data
              let defaultFilter = {
                'per_page': 33
              }

              CommoditieService.getCommodities(defaultFilter).then(res => {
                _this.commoditiesList = res.data.data

                // Set default selected (FAK)
                res.data.data[0].status = 1

                _this.task.commodities.push(res.data.data[0])

                TierService.getTiers().then(res => {
                  _this.tierList = res.data.data
                  _this.strategyList = res.data.strategy
                  _this.recurrenceList = res.data.recurrence

                  _this.task.recurrence = _this.recurrenceList[0]
                  _this.task.strategy = _this.strategyList[0]
                  // If is edit populate values

                  let taskId = this.$route.params.id_schedule

                  if (taskId) {
                    TaskService.getTask(taskId).then(task => {
                      // Customer
                      _this.task.customer = task.data.customer
                      CustomerService.getCustomerTierLocal(this.task.customer).then(res => {
                        this.tierLocal = res.data.customer
                        // Origin and destination
                        _this.task.originsDestinations = task.data.origins_destinations.map((x) => {
                          let strategy = _this.strategyList.find((item) => {
                            return item.id === x.approach
                          })

                          x.approach = strategy
                          return x
                        })

                        // Commodities
                        if (task.data.commodities.length > 0) {
                          _this.task.commodities = []
                          _this.task.commodities = task.data.commodities.map(x => ({
                            id: x.commoditie.id,
                            nome: x.commoditie.nome,
                            status: (x.status)
                          }))
                        }

                        // Is task tariff bulk
                        _this.task.isTaskTariffBulk = task.data.task_tariff_bulk_id ? task.data.task_tariff_bulk_id.hasOwnProperty('id') : false

                        // TransportCompanies
                        if (task.data.shipowners.length > 0) {
                          _this.task.shipowners = []
                          _this.task.shipowners = task.data.shipowners.map(x => ({
                            id: x.shipowner.id,
                            nome: x.shipowner.nome,
                            status: (x.status)
                          }))
                        }

                        // Contacts
                        _this.task.contacts = task.data.recipients.map(x => ({
                          id: x.contact.id,
                          nome: x.contact.nome,
                          email: x.contact.email,
                          phone: x.contact.fone,
                          status: (x.status),
                          wpstatus: (x.wpstatus)
                        }))

                        // Load customer contacts
                        CustomerService.getCustomerContacts(this.task.customer).then(res => {
                          _this.contactList = res.data.data
                        })

                        _this.task.cc = task.data.cc
                        _this.task.cco = task.data.cco

                        if (task.data.sender.hasOwnProperty('id') && task.data.sender.id !== null) {
                          _this.task.sender = task.data.sender
                        }

                        _this.task.defaultSender = task.data.default_sender

                        // Approach
                        _this.task.tier = task.data.tier
                        _this.task.strategy = _this.strategyList.find((x) => {
                          return x.id === task.data.approach
                        })

                        // Show Shipowner
                        if (task.data.show_shipowner) {
                          _this.task.showShipowner = 1
                        }

                        // Not Send With Opened Processes
                        if (task.data.not_send_with_opened_processes) {
                          _this.task.notSendWithOpenedProcesses = 1
                        }

                        // Not Send With Opened Proposals
                        if (task.data.not_send_with_opened_proposals) {
                          _this.task.notSendWithOpenedProposals = 1
                        }

                        // Status
                        if (task.data.status === 1) {
                          _this.task.status = 1
                        } else {
                          _this.task.status = 2
                        }

                        _this.task.optionsPerOriginDestination = task.data.options_per_origin_destination

                        // Valido por pelo menos
                        _this.task.minimumValidity = task.data.minimum_validity

                        // Equipment
                        _this.task.equipments = task.data.equipments.map(x => ({
                          id: x.equipment.id,
                          description: x.equipment.description,
                          status: (x.status)
                        }))

                        // Frequency
                        _this.task.period = task.data.task_frequency.period
                        _this.task.recurrence = _this.recurrenceList.find((x) => {
                          return x.id === task.data.task_frequency.type
                        })

                        // Attach Format
                        _this.task.attachFormat = _this.attachFormatList.find((x) => {
                          return x.id === task.data.attach_format
                        })

                        if (_this.task.recurrence && _this.task.recurrence.id !== 1) {
                          let selectedWeekDays = task.data.task_frequency.weekdays.split(',')

                          for (var index in selectedWeekDays) {
                            let weekDay = parseInt(selectedWeekDays[index])

                            for (var j in _this.weekDayList) {
                              let item = this.weekDayList[j]
                              if (item.id === weekDay) {
                                item.active = true
                                this.$set(this.weekDayList, j, item)
                                break
                              }
                            }
                          }
                        }
                      })
                    }).catch(() => {
                      _this.isLoading = false
                    })
                  }
                  // End edit
                  _this.isLoading = false
                  // CurrencyService.getCurrencies().then(res => {
                  //   _this.currencyList = res.data.data
                  //
                  // })
                })
              })
            })
          })
        })
      })
    })
  },
  computed: {
    receberAllSelected () {
      let total = this.task.originsDestinations.length
      let selected = this.task.originsDestinations.filter((originDestination) => {
        return originDestination.status === 1
      }).length

      if (total === selected) {
        return true
      }

      return false
    },
    aceitaTransbordoAllSelected () {
      let total = this.task.originsDestinations.length
      let selected = this.task.originsDestinations.filter((originDestination) => {
        return originDestination.aceita_transbordo === 1
      }).length

      if (total === selected) {
        return true
      }

      return false
    },
    longTermAllSelected () {
      let total = this.task.originsDestinations.length
      let selected = this.task.originsDestinations.filter((originDestination) => {
        return originDestination.long_term === 1
      }).length

      if (total === selected) {
        return true
      }

      return false
    },
    showWeekdayConfig () {
      if (this.task.recurrence && this.task.recurrence.id === 2) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isShowShipownerEnabled () {
      let _this = this
      // Se nenhuma origem X destino preenchido sim
      if (_this.task.originsDestinations.length <= 0) {
        return true
      }

      // Se houver alguma origem X destino com a média sempre fica desabilitado
      for (let originDestinationIndex in _this.task.originsDestinations) {
        let originDestination = _this.task.originsDestinations[originDestinationIndex]

        if (originDestination.approach && originDestination.approach.id === 2) {
          _this.task.showShipowner = null
          return false
        }
      }

      return true
    },
    defaulSenderList () {
      let ccList = this.ccContactList.filter((el) => {
        return el.id !== 'vendedor'
      })

      return ccList
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    isSuperAdmin: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    toggleVisiblity (status) {
      this.OriginDestinationGroup.visible = status
    },
    addOriginDestinationGroup (OriginDestinationGroupForm) {
      let _this = this
      for (let originId in OriginDestinationGroupForm.origin) {
        for (let destinationId in OriginDestinationGroupForm.destination) {
          let origin = OriginDestinationGroupForm.origin[originId]
          let destination = OriginDestinationGroupForm.destination[destinationId]

          if (origin.id === destination.id) {
            continue
          }

          // Adiciona Origem e Destino
          // Clone object to not change _this.task by reference
          let selectedOriginDestination = {
            'long_term': OriginDestinationGroupForm.long_term,
            'aceita_transbordo': OriginDestinationGroupForm.aceita_transbordo,
            'status': OriginDestinationGroupForm.status,
            'origin': origin,
            'destination': destination,
            'tier': OriginDestinationGroupForm.tier,
            'approach': OriginDestinationGroupForm.approach,
            'options_per_origin_destination': OriginDestinationGroupForm.options_per_origin_destination,
            'minimum_validity': OriginDestinationGroupForm.minimum_validity
          }

          let index = 0
          for (let originDestinationId in _this.task.originsDestinations) {
            let originDestination = _this.task.originsDestinations[originDestinationId]
            // Se não preenchida origem e destino remove e não conta no index
            if (!originDestination.origin && !originDestination.destination) {
              _this.task.originsDestinations.splice(index, 1)
              continue
            }

            if (
              originDestination.origin &&
              originDestination.origin.id === origin.id &&
              originDestination.destination &&
              originDestination.destination.id === destination.id
            ) {
              _this.task.originsDestinations.splice(index, 1)
              break
            }
            index++
          }

          _this.task.originsDestinations.splice(index, 0, selectedOriginDestination)

          _this.OriginDestinationGroup.visible = false
        }
      }

      // Clear group form

      this.OriginDestinationGroup.tier = null
      this.OriginDestinationGroup.status = 1
      this.OriginDestinationGroup.long_term = false
      this.OriginDestinationGroup.aceita_transbordo = true
      this.OriginDestinationGroup.options_per_origin_destination = true
      this.OriginDestinationGroup.approach = null
      this.OriginDestinationGroup.minimum_validity = null
      this.OriginDestinationGroup.origin = null
      this.OriginDestinationGroup.destination = null
      this.OriginDestinationGroup.visible = false
    },
    saveTask () {
      let _this = this
      let taskData = {
        status: (parseInt(_this.task.status) === 1) ? 1 : 2,
        sender: _this.task.sender,
        cc: _this.task.cc,
        cco: _this.task.cco,
        defaultSender: _this.task.defaultSender,
        customer: _this.task.customer.id,
        recipients: _this.task.contacts.map(x => ({ contact: x.id, status: (x.status), wpstatus: (x.wpstatus) })),
        wprecipients: _this.task.contacts.map(x => ({ contact: x.id, wpstatus: (x.wpstatus) })),
        originsDestinations: _this.task.originsDestinations.map(x => ({
          origin: x.origin.id,
          destination: x.destination.id,
          tier: x.tier.id,
          approach: x.approach.id,
          optionsPerOriginDestination: x.options_per_origin_destination,
          minimumValidity: x.minimum_validity,
          status: (x.status !== 1) ? 2 : 1,
          long_term: (x.long_term !== 1) ? 2 : 1,
          aceita_transbordo: (x.aceita_transbordo !== 1) ? 2 : 1
        })),
        equipments: _this.task.equipments.map(x => ({ equipment: x.id, status: (x.status) })),
        commodities: _this.task.commodities.map(x => ({ commoditie: x.id, status: (x.status) })),
        shipowners: _this.task.shipowners.map(x => ({ shipowner: x.id, status: (x.status) })),
        showShipowner: _this.task.showShipowner,
        notSendWithOpenedProcesses: _this.task.notSendWithOpenedProcesses,
        notSendWithOpenedProposals: _this.task.notSendWithOpenedProposals,
        taskFrequency: {
          type: _this.task.recurrence.id,
          weekdays: _this.weekDayList.filter(function (x) {
            return x.active === true
          }).map(x => (x.id)).join(','),
          period: _this.task.period,
          expiresIn: _this.task.expiresIn,
          expiresAt: _this.task.expiresAt
        },
        attachFormat: _this.task.attachFormat.id
      }

      let taskId = this.$route.params.id_schedule

      if (taskId) {
        TaskService.editTask(taskId, taskData).then(response => {
          _this.$router.push({ name: 'SchedulesIndex' })
        }).catch(() => {
          _this.isLoading = false
        })
      } else {
        TaskService.newTask(taskData).then(response => {
          _this.$router.push({ name: 'SchedulesIndex' })
        }).catch(() => {
          _this.isLoading = false
        })
      }
    },
    customLabel ({ nome, email }) {
      // eslint-disable-next-line camelcase
      return `${nome} (${email})`
    },
    toggleLongTermTransbordoAll () {
      let allSelected = this.longTermAllSelected

      for (let i in this.task.originsDestinations) {
        let originDestination = this.task.originsDestinations[i]
        if (!allSelected) {
          originDestination.long_term = 1
        } else {
          originDestination.long_term = 2
        }
      }
    },
    toggleReceberAll () {
      let allSelected = this.receberAllSelected

      for (let i in this.task.originsDestinations) {
        let originDestination = this.task.originsDestinations[i]
        if (!allSelected) {
          originDestination.status = 1
        } else {
          originDestination.status = 2
        }
      }
    },
    toggleAceitaTransbordoAll () {
      let allSelected = this.aceitaTransbordoAllSelected

      for (let i in this.task.originsDestinations) {
        let originDestination = this.task.originsDestinations[i]
        if (!allSelected) {
          originDestination.aceita_transbordo = 1
        } else {
          originDestination.aceita_transbordo = 2
        }
      }
    },
    mailCustomLabel ({ email }) {
      // eslint-disable-next-line camelcase
      return `${email}`
    },
    validCustomer () {
      if (this.task.customer) {
        return true
      }

      return false
    },
    validOriginDestinations () {
      let _this = this
      return new Promise(function (resolve, reject) {
        let promises = []

        for (let originDestinationIndex in _this.task.originsDestinations) {
          let originDestination = _this.task.originsDestinations[originDestinationIndex]
          let component = _this.$refs['originDestination_' + originDestinationIndex]

          promises.push(component[0].validate(originDestination))
        }

        Promise.all(promises).then((result) => {
          resolve(result.indexOf(false) === -1)
        })
      })
    },
    validWeekDay () {
      if (this.showWeekdayConfig) {
        let selected = this.weekDayList.filter((el) => {
          return el.active === true
        })

        return (selected.length > 0)
      }

      return true
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'SchedulesIndex' })
    },
    addOriginDestination (index) {
      let _this = this
      // Clone object to not change _this.task by reference
      let selectedOriginDestination = {}
      selectedOriginDestination = JSON.parse(JSON.stringify(_this.task.originsDestinations[index]))
      // Added empty field filter on began
      selectedOriginDestination.origin = null
      // Adicionar após
      index++

      _this.task.originsDestinations.splice(index, 0, selectedOriginDestination)
    },
    removeOriginDestination (index) {
      let _this = this
      _this.task.originsDestinations.splice(index, 1)

      // Se for o último adiciona um vazio
      if (_this.task.originsDestinations.length === 0) {
        let originDestination = {}
        _this.task.originsDestinations.push(originDestination)
      }
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    showCurrency (number, thousandsSep, defaultValue) {
      return util.numberFormat(number, 2, thousandsSep, defaultValue)
    },
    onSelectRecurrence (val) {
      this.task.recurrence = val
    },
    onSelectAttachFormat (val) {
      this.task.attachFormat = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    setCustomer (value) {
      this.task.customer = value

      this.contactList = []
      this.task.contacts = []
      this.task.contacts = []

      CustomerService.getCustomerTierLocal(this.task.customer).then(res => {
        this.tierLocal = res.data.customer

        CustomerService.getCustomerContacts(this.task.customer).then(res => {
          this.contactList = res.data.data
        })
      })
    },
    setEmployee (value) {
      this.task.sender = value
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CustomerService.getCustomers(filters).then(res => {
        this.hasData = res.data.hasData
        this.customerList = res.data.data
        loading(false)
      })
    },
    customerContactFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'allowEmployeeContacts': true
      }

      // Load customer contacts
      CustomerService.getCustomerContacts(this.task.customer, filters).then(res => {
        this.contactList = res.data.data
        loading(false)
      })
    },
    employeeFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      UserService.getUsers(filters).then(res => {
        this.hasData = res.data.hasData
        this.employeeList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originDestinationFetchOptions (search, loading) {
      let _this = this
      window.clearTimeout(this.timer)

      this.timer = window.setTimeout(function () {
        loading(true)

        let filters = {
          'searchTerm': search
        }

        OriginDestinationService.getOriginsDestinations(filters).then(res => {
          _this.hasData = res.data.hasData
          _this.originsDestinationsList = res.data.data
          loading(false)
        })
      }, _this.delay)
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    commoditiesFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      CommoditieService.getCommodities(filters).then(res => {
        this.hasData = res.data.hasData
        this.commoditiesList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    equipmentFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      EquipmentService.getEquipments(filters).then(res => {
        this.hasData = res.data.hasData
        this.equipmentList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the transport companies search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    transportCompanyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      TransportCompanyService.getTransportCompanies(filters).then(res => {
        this.hasData = res.data.hasData
        this.transportCompanyList = res.data.data
        loading(false)
      })
    },
    onSaveTask () {
      let _this = this
      _this.isLoading = true

      this.$validator.validateAll('form-tariff').then((result) => {
        _this.validOriginDestinations().then((isValidOriginsDestinations) => {
          if (isValidOriginsDestinations && result && _this.validWeekDay() && _this.validCustomer()) {
            if (_this.task.isTaskTariffBulk) {
              global.instanceApp.$alertSwal.modalConfirm(null, 'Este tarifário foi criado automaticamente. Se você editá-lo,<br/> ele perderá o vínculo com o tarifário em massa. Deseja continuar?', null, 'Sim', 'Não', result => {
              }).then(result => {
                if (result.value) {
                  _this.saveTask()
                } else {
                  _this.isLoading = false
                }
              })
            } else {
              _this.saveTask()
            }
          } else {
            _this.$util.scrollToFirstError()
            _this.isLoading = false
          }
        })
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .md-tooltip {
    height:auto
  }
</style>
