<template>
  <div :class="rootClassName">
    <div class="col-6 col-md-6 my-auto">
      <strong v-show="title">{{title}}:</strong>
      <span v-if="value">
        {{ value[labelField] }}
      </span>
      <i v-else>
        {{ $t(emptyMessage)}}
      </i>
      <slot name="info"></slot>
    </div>
    <div class="col-6 col-md-6 ">
      <div class="md-form mb-0 mt-0">
            <v-select :name="name" :placeholder="placeholder" :label="labelField" :clearable="clearable" :options="options" @search="delayFetchOptions" v-model="selectedValue"  @input="onSelect" >
              <template slot="no-options">
                {{ noOptions }}
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option[labelField] }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option[labelField] }}
                </div>
              </template>
            </v-select>
      </div>
    </div>
    <div class="col-12">
      <transition name="slide" mode="">
        <small v-if="error" class="royalc-error-field">{{ error }}</small>
      </transition>
    </div>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'AdvancedSearch',
  props: {
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    emptyMessage: {
      type: String,
      default: global.instanceApp.$i18n.t('noting selected')
    },
    rootClassName: {
      type: String,
      default: 'form-group'
    },
    value: {
      required: false,
      type: Object,
      default: null
    },
    apiUrl: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    fetchOptions: {
      type: Function,
      default: null
    },
    searchField: {
      type: String,
      default: ''
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    labelField: {
      type: String,
      default: 'name'
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    title: {
      type: String,
      default: 'Cliente'
    },
    error: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    vSelect
  },
  data () {
    return {
      searchTerm: null,
      /** @TODO: set selected value on edit */
      selectedValue: null,
      timer: 0
    }
  },
  methods: {
    onSelect (val) {
      this.selectedValue = null
      this.$emit('onSelect', val)
    },
    delayFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          _this.fetchOptions(search, loading)
        }, this.delay)
      }
    }
  }
}
</script>
